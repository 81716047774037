import { PecType } from "apina-frontend";

export const pecTypeNames: Record<PecType, string> = {
    FAMILIARISATION_VOYAGE: "Perehdytysmatka",
    FAIRWAY_KNOWLEDGE_TEST: "Väylätuntemuskoe",
    PRACTICAL_PILOTAGE_TEST: "Tutkintoajo",
    SHIP_SIMULATOR_TEST: "Simulaattorikoe",
};

export const pecTypeNamesLower: Record<PecType, string> = {
    FAMILIARISATION_VOYAGE: "perehdytysmatka",
    FAIRWAY_KNOWLEDGE_TEST: "väylätuntemuskoe",
    PRACTICAL_PILOTAGE_TEST: "tutkintoajo",
    SHIP_SIMULATOR_TEST: "simulaattorikoe",
};

export const englishPecTypeNames: Record<PecType, string> = {
    FAMILIARISATION_VOYAGE: "Familiarisation voyage",
    FAIRWAY_KNOWLEDGE_TEST: "Fairway knowledge test",
    PRACTICAL_PILOTAGE_TEST: "Practical pilotage test",
    SHIP_SIMULATOR_TEST: "Ship simulator test",
};
